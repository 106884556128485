import { Route } from '@angular/router';
import { PmCorePageNotFoundComponent } from '@pm/core/page';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('@pm/home/page').then((m) => m.PmHomePageModule),
    data: { state: 'dark' },
  },
  {
    path: 'auth',
    loadChildren: () => import('@pm/auth/page').then((m) => m.PmAuthPageModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('@pm/about/page').then((m) => m.PmAboutPageModule),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('@pm/clients/page').then((m) => m.PmClientsPageModule),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('@pm/terms/page').then((m) => m.PmTermsPageModule),
  },
  // {
  //   path: 'application',
  //   loadChildren: () =>
  //     import('@pm/application/page').then((m) => m.PmApplicationPageModule),
  // },
  // {
  //   path: 'countries',
  //   loadChildren: () =>
  //     import('@pm/countries/page').then((m) => m.PmCountriesPageModule),
  // },
  {
    path: 'domains',
    loadChildren: () =>
      import('@pm/domains/page').then((m) => m.PmDomainsPageModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('@pm/blog/page').then((m) => m.PmBlogPageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@pm/ip/page').then((m) => m.pmIpPageSpecialRoutes),
    data: { state: 'dark' },
  },
  {
    path: '**',
    component: PmCorePageNotFoundComponent,
  },
];
