import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Injectable({
  providedIn: 'root',
})
export class PmCoreSeoSchemaService {
  isBrowser!: boolean;
  private _renderer2: Renderer2;
  constructor(
    @Inject(PLATFORM_ID) readonly platformId: string,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this.rendererFactory.createRenderer(null, null);
    this.isBrowser = this.platformId === 'browser';
  }

  attachAllSchemas(): void {
    // this.setSchema(this.generateOrganizationSchema());
    // this.setSchema(this.generateSiteLinksSearchBoxSchema());
    // this.setSchema(this.generateWebPageHeaderSchema());

    this.setSchema([
      this.generateOrganizationSchema(),
      this.generateSiteLinksSearchBoxSchema(),
      this.generateWebPageHeaderSchema(),
    ]);
  }

  generateOrganizationSchema(): unknown {
    const url = 'https://' + this.appConfig.domain;

    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url,
      sameAs: ['https://www.linkedin.com/in/profitmark'],
      logo: url + '/assets/ui/profitmark-logo-white.svg',
      name: 'Profitmark',
      description:
        'Profitmark is the international online registrar of trade marks. Current of 10 years we have registered thousand trade marks for our clients worldwide.',
      email: this.appConfig.contactEmail,
      telephone: this.appConfig.contactNumber,
    };
  }

  generateSiteLinksSearchBoxSchema(): unknown {
    const url = 'https://' + this.appConfig.domain;

    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: this.appConfig.siteName ?? 'Profitmark',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    };
  }

  generateWebPageHeaderSchema(): unknown {
    return {
      '@context': 'https://schema.org',
      '@type': 'WPHeader',
      headline: this.appConfig.pageTitle,
      description: this.appConfig.pageDescription,
      keywords: 'trademark, registration, search, application, patent, design',
    };
  }

  setSchema(schema: unknown): void {
    if (this.isBrowser) {
      const script = this._renderer2.createElement('script');
      script.type = `application/ld+json`;
      script.text = JSON.stringify(schema);
      this._renderer2.appendChild(this._document.body, script);
    }
  }
}
