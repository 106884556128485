import { Observable, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeoLocation } from '@pm/core/utils';

@Injectable({
  providedIn: 'root',
})
export class PmCoreGeoiplookupService {
  constructor(private readonly _http: HttpClient) {}

  getGeoIpLookup$() {
    return this._http.get<GeoLocation>('https://freeipapi.com/api/json');
  }

  getCountryCode$(): Observable<string> {
    return this.getGeoIpLookup$().pipe(
      map((geoIpLookup: GeoLocation) => geoIpLookup.countryCode)
    );
  }
}
