import { createAction, props } from '@ngrx/store';

import { GeoLocationEntity } from './geo-location.models';

export const initGeoLocation = createAction('[GeoLocation Page] Init');

export const loadGeoLocationSuccess = createAction(
  '[GeoLocation/API] Load GeoLocation Success',
  props<{ geoLocation: Partial<GeoLocationEntity> }>()
);

export const loadGeoLocationFailure = createAction(
  '[GeoLocation/API] Load GeoLocation Failure',
  props<{ error: any }>()
);
