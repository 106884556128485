/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { sharkScript } from '../models/pm-core-shark.default';
import { DOCUMENT } from '@angular/common';

declare let sharkWidget: {
  openWidget: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class PmCoreSharkService {
  isBrowser!: boolean;
  private _renderer2: Renderer2;
  constructor(
    @Inject(PLATFORM_ID) readonly platformId: any,
    @Inject(DOCUMENT) private _document: any,
    private _authService: PmAuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this.rendererFactory.createRenderer(null, null);
    this.isBrowser = this.platformId === 'browser';
  }

  async injectChat() {
    if (this.isBrowser && this.appConfig.shark) {
      const decoded = await this._authService.getDecodedToken();
      if (decoded && decoded.drupal) {
        const userData: any = {};
        if (
          decoded.drupal.displayname &&
          decoded.drupal.displayname.trim().length > 0
        ) {
          userData['name'] = decoded.drupal.displayname.trim();
        }
        if (decoded.drupal.email && decoded.drupal.email.length > 0) {
          userData['email'] = decoded.drupal.email;
        }
        if (decoded.drupal.phone && decoded.drupal.phone.length > 0) {
          userData['phone'] = decoded.drupal.email;
        }
        (window as any)._udata = userData;
      }

      const chat = this._renderer2.createElement('script');
      chat.id = 'shark-widget';
      chat.text = sharkScript(this.appConfig.shark);

      this._renderer2.appendChild(this._document.body, chat);
    }
  }

  invokeChat() {
    if (this.isBrowser && this.appConfig.shark) {
      (window as any).sharkWidget.openWidget();
    }
  }

  openChat() {
    sharkWidget.openWidget();
  }

  setUserData(data: { name?: string; email?: string; phone?: string }) {
    (window as any)._udata = data;
  }
}
