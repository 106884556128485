import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GEO_LOCATION_FEATURE_KEY,
  GeoLocationState,
  geoLocationAdapter,
} from './geo-location.reducer';

// Lookup the 'GeoLocation' feature state managed by NgRx
export const selectGeoLocationState = createFeatureSelector<GeoLocationState>(
  GEO_LOCATION_FEATURE_KEY
);

const { selectAll, selectEntities } = geoLocationAdapter.getSelectors();

export const selectGeoLocationLoaded = createSelector(
  selectGeoLocationState,
  (state: GeoLocationState) => state.loaded
);

export const selectGeoLocationError = createSelector(
  selectGeoLocationState,
  (state: GeoLocationState) => state.error
);

export const selectAllGeoLocation = createSelector(
  selectGeoLocationState,
  (state: GeoLocationState) => selectAll(state)
);

export const selectGeoLocationEntities = createSelector(
  selectGeoLocationState,
  (state: GeoLocationState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectGeoLocationState,
  (state: GeoLocationState) => state.selectedId
);

export const selectEntity = createSelector(
  selectGeoLocationEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
