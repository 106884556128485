import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as GeoLocationActions from './geo-location.actions';
import { GeoLocationEntity } from './geo-location.models';

export const GEO_LOCATION_FEATURE_KEY = 'geoLocation';

export interface GeoLocationState extends EntityState<GeoLocationEntity> {
  selectedId?: string | number; // which GeoLocation record has been selected
  loaded: boolean; // has the GeoLocation list been loaded
  error?: string | null; // last known error (if any)
}

export interface GeoLocationPartialState {
  readonly [GEO_LOCATION_FEATURE_KEY]: GeoLocationState;
}

export const geoLocationAdapter: EntityAdapter<GeoLocationEntity> =
  createEntityAdapter<GeoLocationEntity>();

export const initialGeoLocationState: GeoLocationState =
  geoLocationAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialGeoLocationState,
  on(GeoLocationActions.initGeoLocation, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GeoLocationActions.loadGeoLocationSuccess, (state, { geoLocation }) =>
    geoLocationAdapter.setOne(geoLocation as GeoLocationEntity, {
      ...state,
      loaded: true,
    })
  ),
  on(GeoLocationActions.loadGeoLocationFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function geoLocationReducer(
  state: GeoLocationState | undefined,
  action: Action
) {
  return reducer(state, action);
}
