import { catchError, of, switchMap } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PmCoreGeoiplookupService } from '@pm/core/geo';

import * as GeoLocationActions from './geo-location.actions';
import * as GeoLocationFeature from './geo-location.reducer';

@Injectable()
export class GeoLocationEffects {
  private actions$ = inject(Actions);

  constructor(private readonly _geo: PmCoreGeoiplookupService) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeoLocationActions.initGeoLocation),
      switchMap(() =>
        this._geo.getGeoIpLookup$().pipe(
          switchMap((geoLocation) =>
            of(
              GeoLocationActions.loadGeoLocationSuccess({
                geoLocation: {
                  ...geoLocation,
                  id: geoLocation.ipAddress,
                },
              })
            )
          ),
          catchError((error) =>
            of(GeoLocationActions.loadGeoLocationFailure({ error }))
          )
        )
      ),
      catchError((error) => {
        console.error('Error', error);
        return of(GeoLocationActions.loadGeoLocationFailure({ error }));
      })
    )
  );
}
