import { Inject, Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Injectable({
  providedIn: 'root',
})
export class PmCoreHotjarService {
  constructor(@Inject(APP_CONFIG) private _config: AppConfig) {}

  public init(): void {
    const hjid = this._config.hjid;
    const hjsv = 6;
    if (!hjid) {
      return;
    }
    Hotjar.init(hjid, hjsv);
  }
}
