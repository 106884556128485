import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { PmAuthBloc, PmAuthDataService } from '@pm/auth/data';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { Observable } from 'rxjs';

@Component({
  selector: 'pm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  bloc = new PmAuthBloc(this._authDrupalService, this._authService);
  @Input()
  theme: 'light' | 'dark' = 'light';

  forcedTheme!: 'light' | 'dark' | undefined;

  contactEmail = this.appConfig.contactEmail;
  contactPhone = this.appConfig.contactNumber;

  languages = [
    { name: 'ENG', code: 'en' },
    { name: 'UKR', code: 'uk' },
  ];

  isLoggedIn$ = this._authService.isLoggedIn$;
  translations$!: Observable<unknown>;

  isBlogEnabled: boolean | undefined = this.appConfig.isBlogEnabled;

  isSticked = false;

  readonly STICKED_THRESHOLD = 33;

  constructor(
    private _authService: PmAuthService,
    private _authDrupalService: PmAuthDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {}

  ngOnInit() {
    this._detectSticked();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this._detectSticked();
  }

  private _detectSticked() {
    if (window.scrollY > this.STICKED_THRESHOLD) {
      this.isSticked = true;
      this.forcedTheme = 'light';
    } else {
      this.isSticked = false;
      this.forcedTheme = undefined;
    }
  }
}
