import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { COMPANY_ADDRESS } from '@pm/core/utils';
import { PROFIT_WEBSITES } from './models';

@Component({
  selector: 'pm-footer',
  templateUrl: './pm-ui-footer.component.html',
  styleUrls: ['./pm-ui-footer.component.scss'],
})
export class PmUiPmFooterComponent implements OnInit {
  companyName!: string;
  companyAddress!: string;
  contactEmail = this._appConfig.contactEmail;
  contactNumber = this._appConfig.contactNumber;
  websites: string[] = [];
  currentYear = new Date().getFullYear();
  whatsappLink = this._appConfig.whatsappNumber
    ? `https://wa.me/${this._appConfig.whatsappNumber}`
    : '';

  constructor(@Inject(APP_CONFIG) private _appConfig: AppConfig) {}

  ngOnInit() {
    const country = (this._appConfig.mainCountry ||
      'eu') as keyof typeof COMPANY_ADDRESS;
    if (COMPANY_ADDRESS[country]) {
      this.companyName = COMPANY_ADDRESS[country].name;
      this.companyAddress = COMPANY_ADDRESS[country].address;
    } else {
      this.companyName = 'Profitmark International';
      this.companyAddress = '';
    }

    this.websites = PROFIT_WEBSITES.filter(
      (website) => website !== this._appConfig.domain,
    );
  }
}
