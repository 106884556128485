import { MegaMenuItem, MenuItem } from 'primeng/api';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';

import { CommonModule } from '@angular/common';
import { Component, computed, Inject, Input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PmAuthBloc, PmAuthDataService } from '@pm/auth/data';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { ButtonModule } from 'primeng/button';
import { PmHeaderLogoComponent } from '../pm-header-logo/pm-header-logo.component';

@Component({
  selector: 'pm-header-megamenu',
  standalone: true,
  imports: [
    CommonModule,
    MegaMenuModule,
    TranslocoModule,
    MenubarModule,
    ButtonModule,
    PmHeaderLogoComponent,
  ],
  templateUrl: './pm-header-megamenu.component.html',
  styleUrls: ['./pm-header-megamenu.component.scss'],
})
export class PmHeaderMegamenuComponent {
  bloc = new PmAuthBloc(this._authDrupalService, this._authService);

  @Input()
  theme: 'light' | 'dark' = 'light';

  @Input()
  isSticked = false;

  isAuthorized = toSignal(this.bloc.isLoggined$);

  items: MegaMenuItem[] = [];

  mobileMenu: Signal<MenuItem[]> = computed(() => {
    return [
      ...this._mapMenuItems(this.items),
      {
        label: this._transloco.translate('header.menu.blog'),
        routerLink: '/blog',
      },
      ...(this.isAuthorized()
        ? [
            {
              label: this._transloco.translate('header.menu.dashboard'),
              command: () => this.goToDashboard(),
            },
            {
              label: this._transloco.translate('header.menu.signout'),
              command: () => this.signOut(),
            },
          ]
        : [
            {
              label: this._transloco.translate('header.menu.signin'),
              routerLink: '/auth/signin',
            },
          ]),
    ];
  });

  constructor(
    private readonly _transloco: TranslocoService,
    private _authService: PmAuthService,
    private _authDrupalService: PmAuthDataService,
    @Inject(APP_CONFIG) private readonly _config: AppConfig,
  ) {
    this.items = this._buildMenuItems();
  }

  goToDashboard() {
    this.bloc.redirectToDashboard();
  }

  signOut() {
    this._authService.signOut();
  }

  private readonly _buildMenuItems = () => {
    const trademarkRegistrationMenuitem = this._mapTrademarkMenuItems();
    return [
      {
        label: this._transloco.translate('header.menu.services'),
        items: [
          [trademarkRegistrationMenuitem],
          [
            {
              label: this._transloco.translate(
                'header.menu.patent-registration',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.copy-right-registration-usa',
                  ),
                  routerLink: '/copyright-us',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.design-registration-eu',
                  ),
                  routerLink: '/design-eu',
                },
                {
                  label: this._transloco.translate(
                    'header.menu.invention-registration',
                  ),
                  routerLink: '/invention',
                },
              ],
            },
          ],
          [
            {
              label: this._transloco.translate(
                'header.menu.additional-services',
              ),
              items: [
                {
                  label: this._transloco.translate(
                    'header.menu.domains-registration',
                  ),
                  routerLink: '/domains',
                },
              ],
            },
          ],
        ],
      },
      {
        label: this._transloco.translate('header.menu.our-clients'),
        routerLink: '/clients',
      },
      {
        label: this._transloco.translate('header.menu.about-us'),
        routerLink: '/about',
      },
    ];
  };

  private readonly _mapMenuItems = (items: MegaMenuItem[]) =>
    items.map((item): MenuItem => {
      if (item.items) {
        return {
          ...item,
          items: item.items.flat(),
        };
      }

      return {
        ...item,
      };
    });

  private _mapTrademarkMenuItems() {
    const inEuLabel = this._transloco.translate('ui.trademark-in-eu');

    const countryCode = this._config.mainCountry || '';
    const labelText = this._transloco.translate('ui.trademark-in', {
      country: countryCode.toUpperCase(),
    });
    const currentCountryMenuItem = {
      label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${countryCode.toLowerCase()} border-round"></div><div>${labelText}</div></div>`,
      escape: false,
      routerLink: '/',
    };

    if (this._config.isEuPageEnabled) {
      return {
        label: this._transloco.translate('header.menu.trademark-registration'),
        items: [
          currentCountryMenuItem,
          {
            label: `<div class="flex gap-2 w-20rem"><div class="fi fi-eu border-round"></div><div>${inEuLabel}</div></div>`,
            escape: false,
            routerLink: 'eu',
          },
        ],
      };
    } else {
      if (this._config.mainCountry?.toUpperCase() === 'EU') {
        const localWebsites = ['es', 'pl', 'pt'];
        const currentCountryMenuItem = {
          label: `<div class="flex gap-2 w-20rem"><div class="fi fi-eu border-round"></div><div>${inEuLabel}</div></div>`,
          escape: false,
          routerLink: '/',
        };

        const items = localWebsites.map((countryCode) => {
          const labelText = this._transloco.translate('ui.trademark-in', {
            country: countryCode.toUpperCase(),
          });
          return {
            label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${countryCode.toLowerCase()} border-round"></div><div>${labelText}</div></div>`,
            escape: false,
            url: `https://profitmark.${countryCode}`,
          };
        });

        return {
          label: this._transloco.translate(
            'header.menu.trademark-registration',
          ),
          items: [currentCountryMenuItem, ...items],
        };
      }

      return {
        label: this._transloco.translate('header.menu.trademark-registration'),
        items: [currentCountryMenuItem],
      };
    }
  }
}
