<ng-container *ngIf="translations$ | async"></ng-container>

<div
  class="profit-header"
  [ngClass]="{
    'profit-header--light': theme === 'light',
    'profit-header--dark': theme === 'dark',
    'profit-header--sticked': isSticked
  }"
>
  <div class="profit-header__container">
    <div class="profit-header__container__top-bar">
      <div class="profit-header__container__top-bar__left">
        <!-- <div class="profit-header__container__top-bar__language">
          <p-dropdown [options]="languages" optionLabel="name"></p-dropdown>
        </div> -->
      </div>
      <div class="profit-header__container__top-bar__right">
        @if (isBlogEnabled) {
          <a
            [routerLink]="'blog'"
            class="hidden lg:inline"
            [ngClass]="{
              'text-white': theme === 'dark',
              'text-900': theme === 'light'
            }"
            >{{ 'ui.blog' | transloco }}</a
          >
        }
        <div class="flex align-items-end">
          <span
            style="font-size: 0.8rem"
            class="pi pi-envelope ml-2 mr-1"
          ></span>
          <span>{{ contactEmail }}</span>
        </div>
        @if (contactPhone) {
          <div class="flex align-items-end">
            <span
              style="font-size: 0.8rem"
              class="pi pi-phone ml-2 mr-1"
            ></span>
            <a
              [href]="'tel:' + contactPhone"
              class="no-underline"
              [ngClass]="{
                'text-white': theme === 'dark',
                'text-900': theme === 'light'
              }"
              >{{ contactPhone }}</a
            >
          </div>
        }
      </div>
    </div>
    <div class="profit-header__container__navigation">
      <pm-header-megamenu
        ngSkipHydration
        [isSticked]="isSticked"
        [theme]="forcedTheme ?? theme"
      />
    </div>
  </div>

  <ng-template #menuButtons> </ng-template>
</div>
