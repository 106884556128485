import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PmAuthUtilsModule } from '@pm/auth/utils';

import { PmCoreSharkService } from './services/pm-core-shark.service';

@NgModule({
  imports: [CommonModule, PmAuthUtilsModule],
  providers: [PmCoreSharkService],
})
export class PmCoreSharkModule {}
