import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'pm-header-logo',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    [attr.width]="svgWidth"
    [attr.height]="svgHeight"
    viewBox="0 0 189 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    [class]="theme === 'dark' ? 'dark' : 'light'"
  >
    <g clip-path="url(#clip0_115_18097)">
      <path
        d="M50.9394 57.917H6.6871C3.0164 57.917 0.0390625 54.9226 0.0390625 51.231V6.72604C0.0390625 3.03438 3.0164 0.0400391 6.6871 0.0400391H50.9394C54.6101 0.0400391 57.5874 3.03438 57.5874 6.72604V51.231C57.5874 54.9226 54.6101 57.917 50.9394 57.917ZM6.6871 2.13198C4.1584 2.13198 2.07834 4.22391 2.07834 6.72604V51.231C2.07834 53.7741 4.1584 55.8661 6.6871 55.8661H50.9394C53.4681 55.8661 55.5481 53.7741 55.5481 51.231V6.72604C55.5481 4.1829 53.4681 2.13198 50.9394 2.13198H6.6871Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M69.2148 29.698V38.8041H67.8281V19.1973H76.1892C78.9626 19.1973 80.3493 20.5919 80.3493 23.3811V25.4731C80.3493 28.2623 78.9626 29.657 76.1892 29.657L69.2148 29.698ZM69.2148 20.5919V28.3033H76.1892C78.0653 28.3033 78.9626 27.3599 78.9626 25.5141V23.3811C78.9626 21.5353 78.0245 20.5919 76.1892 20.5919H69.2148Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M83.1211 38.8047V24.7764H84.0999L84.5078 26.171C85.4459 25.2276 86.8326 24.7764 88.6679 24.7764V26.171C86.7918 26.171 85.4051 26.6222 84.5078 27.5656V38.7636H83.1211V38.8047Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M101.922 34.5788C101.922 37.368 100.535 38.7627 97.7617 38.7627H94.9882C92.2148 38.7627 90.8281 37.368 90.8281 34.5788V29.0003C90.8281 26.211 92.2148 24.8164 94.9882 24.8164H97.7617C100.535 24.8164 101.922 26.211 101.922 29.0003V34.5788ZM92.174 34.5788C92.174 36.4656 93.1121 37.368 94.9475 37.368H97.7209C99.597 37.368 100.494 36.4246 100.494 34.5788V29.0003C100.494 27.1134 99.5562 26.211 97.7209 26.211H94.9475C93.0713 26.211 92.174 27.1545 92.174 29.0003V34.5788Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M108.166 25.4731H113.019V26.8677H108.166V38.763H106.779V26.8677H104.699V25.4731H106.779V23.3811C106.779 20.5919 108.166 19.1973 110.939 19.1973H113.019V20.5919H110.939C109.104 20.5919 108.166 21.5353 108.166 23.3811V25.4731Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M117.219 19.1973V21.9865H115.832V19.1973H117.219ZM117.219 24.7758V38.763H115.832V24.7758H117.219Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M123.459 24.7758H128.312V26.1704H123.459V34.5792C123.459 36.466 124.397 37.3684 126.232 37.3684H128.312V38.763H126.232C123.459 38.763 122.072 37.3684 122.072 34.5792V26.2114H119.992V24.8168H122.072V20.5919L123.459 19.1973V24.7758Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M136.672 19.1973H139.037L145.033 37.3684L151.028 19.1973H153.394V38.8041H152.007V20.5919L146.175 38.763H143.932L138.099 20.5919V38.763H136.713L136.672 19.1973Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M165.918 30.3949C165.918 27.6057 164.531 26.211 161.758 26.211H157.598V24.8164H161.758C165.469 24.8164 167.305 26.6622 167.305 30.4359V38.8447H166.326L165.918 37.4501C164.98 38.3935 163.593 38.8447 161.758 38.8447H160.371C157.598 38.8447 156.211 37.4501 156.211 34.6608C156.211 31.8716 157.598 30.4769 160.371 30.4769H161.758C163.593 30.4769 165.021 30.9281 165.918 31.8716V30.3949ZM161.717 37.368C163.552 37.368 164.98 36.9168 165.877 35.9734V33.1842C164.939 32.2407 163.552 31.7895 161.717 31.7895H160.33C158.495 31.7895 157.557 32.733 157.557 34.5788C157.557 36.4246 158.495 37.368 160.33 37.368H161.717Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M170.078 38.8047V24.7764H171.057L171.465 26.171C172.403 25.2276 173.79 24.7764 175.625 24.7764V26.171C173.79 26.171 172.362 26.6222 171.465 27.5656V38.7636H170.078V38.8047Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M179.129 19.1973V31.0926H180.516L187.041 24.8168H188.836L181.739 31.8309L188.836 38.8041H187.041L180.516 32.5282H179.129V38.8041H177.742V19.1973H179.129Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M19.4545 23.5035H13.3367V34.0453C13.3367 36.3833 14.5195 37.5728 16.8443 37.5728H19.4545V39.3366H16.8443C13.3367 39.3366 11.5829 37.5728 11.5829 34.0863V23.5035H8.97266V21.7397H11.5829V16.4484L13.3367 14.6846V21.7397H19.4545V23.5035Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
      <path
        d="M45.6785 39.2951H43.9247V26.9896C43.9247 24.6516 42.6195 23.462 40.0501 23.462C37.4806 23.462 35.8492 24.0363 35.1966 25.2258V39.2951H33.4428V26.8255C33.402 24.6105 32.2192 23.503 29.9353 23.503C27.6105 23.503 25.8567 24.0773 24.7147 25.2668V39.3361H22.9609V21.7393H24.1845L24.7147 23.503C25.8567 22.3135 27.6105 21.7393 29.9353 21.7393C31.9337 21.7393 33.3612 22.3135 34.2177 23.503C35.7268 22.3135 37.4806 21.7393 39.5199 21.7393C43.5984 21.7393 45.6377 23.503 45.6377 27.0306V39.2951H45.6785Z"
        fill="white"
        stroke="white"
        stroke-width="0.131734"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_115_18097">
        <rect width="189" height="58" fill="white" />
      </clipPath>
    </defs>
  </svg>`,
  styles: [
    `
      .light {
        path {
          fill: #000;
          stroke: #000;
        }
      }
      .dark {
        path {
          fill: #fff;
          stroke: #fff;
        }
      }
    `,
  ],
})
export class PmHeaderLogoComponent {
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  get svgWidth(): number {
    return this.size === 'sm' ? 158 : this.size === 'lg' ? 285 : 189;
  }

  get svgHeight(): number {
    return this.size === 'sm' ? 48 : this.size === 'lg' ? 87 : 58;
  }
}
