/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs';

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { PmCoreHotjarService } from '@pm/core/hotjar';
import { PmCoreSeoSchemaService } from '@pm/core/seo';
import { PmCoreSharkService } from '@pm/core/shark';

@Component({
  selector: 'profitmark-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'profitmark-eu';
  isDark = false;
  platformId!: string;

  navigation$ = this._router.events
    .pipe(filter((event) => event instanceof ActivationEnd))
    .subscribe((event: any) => {
      this.isDark = event.snapshot.data.state === 'dark';
    });

  constructor(
    private readonly _router: Router,
    private readonly _shark: PmCoreSharkService,
    private readonly _schema: PmCoreSeoSchemaService,
    private readonly _gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly _hotjar: PmCoreHotjarService,
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (this.platformId === 'browser') {
      this._gtmService.addGtmToDom();
    }

    this._shark.injectChat();
    this._schema.attachAllSchemas();
    this._hotjar.init();
  }

  async showCookieConsent(): Promise<void> {}
}
