import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GeoLocationEffects } from './/geo-location.effects';
import { GeoLocationFacade } from './/geo-location.facade';
import * as fromGeoLocation from './/geo-location.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromGeoLocation.GEO_LOCATION_FEATURE_KEY,
      fromGeoLocation.geoLocationReducer
    ),
    EffectsModule.forFeature([GeoLocationEffects]),
  ],
  providers: [GeoLocationFacade],
})
export class PmCoreGeoStoreModule {}
