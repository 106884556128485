<ng-container *transloco="let t; read: 'ui'">
  @if (config.appStoreLink && config.playMarketLink) {
    <div class="pm-ui-mobile-links line-height-4 mb-4">
      <div class="pm-ui-mobile-links__content pb-4">
        <div class="grid">
          <div
            class="col-12 md:col-6 lg:col text-2xl uppercase font-bold flex align-items-center"
          >
            <div class="text-center md:text-left md:w-6 line-height-1">
              {{ t('yout-trademarks-are-always-there') }}
            </div>
          </div>
          <div
            class="col-12 md:col-6 lg:col flex flex-column md:flex-row justify-content-center md:justify-content-end gap-2 flex align-items-center"
          >
            <a [href]="config.appStoreLink" target="_blank"
              ><img
                src="/assets/ui/app_store_icon.svg"
                alt="App Store Profitmark"
                title="App Store Profitmark"
                width="220"
            /></a>
            <a [href]="config.playMarketLink" target="_blank"
              ><img
                src="/assets/ui/google_play_icon.svg"
                alt="Google Play Profitmark"
                title="Google Play Profitmark"
                width="220"
            /></a>
          </div>
        </div>
      </div>
      <div class="pm-ui-mobile-links__divider"></div>
    </div>
  }
</ng-container>
