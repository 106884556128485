export const sharkScript = (code: string) => `
(function(d) {
  var f = d.getElementsByTagName('SCRIPT')[0],
  p = d.createElement('SCRIPT');
  p.type = 'text/javascript';
  p.setAttribute('charset', 'utf-8');
  p.async = true;
  p.id = 'shark-script';
  p.src = 'https://api.shark.chat/widget/c/${code}';
  f.parentNode.insertBefore(p, f);
})(document);
`;
