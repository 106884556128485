import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { filter, pairwise } from 'rxjs';

import { ViewportScroller, isPlatformServer } from '@angular/common';
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Event,
  NavigationEnd,
  Router,
  RouterModule,
  Scroll,
} from '@angular/router';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { PersistStateModule } from '@ngrx-addons/persist-state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authInterceptorProviders } from '@pm/auth/utils';
import { getConfigValueFactory } from '@pm/config';
import { GeoLocationFacade, PmCoreDataModule } from '@pm/core/data';
import { PmCoreSharkModule } from '@pm/core/shark';
import { APP_TYPE, AppType } from '@pm/core/utils';
import { PmUiPmFooterModule } from '@pm/ui/footer';
import { PmUiPmHeaderModule } from '@pm/ui/header';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { LayoutComponent } from './components/layout/layout.component';
import { cookieConfig } from './cookie-consent-config';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      // scrollPositionRestoration: 'enabled',
    }),
    PmUiPmHeaderModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PersistStateModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'Profitmark EU',
    }),
    HttpClientModule,
    RecaptchaV3Module,
    PmUiPmFooterModule,
    GoogleTagManagerModule,
    PmCoreSharkModule,
    PmCoreDataModule,
    TranslocoRootModule,
    ToastModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxStripeModule.forRoot(),
  ],
  providers: [
    { provide: APP_ID, useValue: 'profitmark-web' },
    { provide: APP_TYPE, useValue: AppType.landing },
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
      withInterceptors([withHttpCacheInterceptor()]),
    ),
    provideClientHydration(),
    provideHttpCache(),
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: getConfigValueFactory('recaptchaKey'),
      deps: [Injector],
    },
    {
      provide: 'googleTagManagerId',
      useFactory: getConfigValueFactory('gtm'),
      deps: [Injector],
    },
    CookieService,
    ...authInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: (geo: GeoLocationFacade, injector: Injector) => () => {
        const platform = injector.get(PLATFORM_ID);
        if (isPlatformServer(platform)) {
          return Promise.resolve();
        }
        return geo.init();
      },
      multi: true,
      deps: [GeoLocationFacade, Injector],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise(),
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else if (
          previousEvent.routerEvent instanceof NavigationEnd &&
          event.routerEvent instanceof NavigationEnd
        ) {
          // forward navigation
          if (
            previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !==
            event.routerEvent.urlAfterRedirects.split('?')[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
