<div
  class="pm-header-megamenu"
  [ngClass]="{
    'pm-header-megamenu--light': theme === 'light',
    'pm-header-megamenu--dark': theme === 'dark'
  }"
>
  <p-megaMenu
    [model]="items"
    orientation="horizontal"
    class="w-full hidden lg:flex"
  >
    <ng-template pTemplate="start">
      <a [routerLink]="['/']" class="mr-4">
        <pm-header-logo
          [size]="isSticked ? 'sm' : 'md'"
          [theme]="theme"
        ></pm-header-logo>
      </a>
    </ng-template>
    <ng-template pTemplate="end">
      @defer {
        @if (isAuthorized()) {
          <p-button
            styleClass="p-button-primary"
            [label]="'ui.dashboard' | transloco"
            (onClick)="goToDashboard()"
          ></p-button>
          <p-button
            [text]="true"
            icon="pi pi-sign-out"
            severity="secondary"
            (onClick)="signOut()"
          ></p-button>
        } @else {
          <p-button
            [label]="'ui.sign-in' | transloco"
            [icon]="'pi pi-sign-in'"
            [severity]="'primary'"
            [routerLink]="['/auth/signin']"
          ></p-button>
        }
      }
    </ng-template>
  </p-megaMenu>
  <p-menubar [model]="mobileMenu()" id="menubar" class="w-full flex lg:hidden">
    <ng-template pTemplate="start">
      <a [routerLink]="['/']">
        <pm-header-logo [size]="'sm'" [theme]="theme"></pm-header-logo>
      </a>
    </ng-template>
  </p-menubar>
</div>
