@defer {
  <div class="pm-ui-footer line-height-4">
    <div class="pm-ui-footer__content pt-8 pb-4">
      <pm-ui-mobile-links />
      <div class="grid">
        <div class="col-12 md:col-6 lg:col">
          <div>
            <img
              src="/assets/ui/profitmark-logo-white-2.svg"
              alt="Profitmark"
              width="226"
              height="70"
            />
          </div>
          <div class="mt-4 flex gap-3">
            <div>
              <a
                href="https://www.linkedin.com/in/profitmark"
                target="_blank"
                name="linkedin"
                rel="noopener"
                ><img
                  src="/assets/ui/linkedin.svg"
                  alt="linkedin"
                  width="32"
                  height="33"
              /></a>
            </div>
            <div class="text-lg">{{ contactEmail }}</div>
          </div>
          <div class="mt-0 flex gap-3">
            @if (whatsappLink) {
              <div>
                <a
                  href="{{ whatsappLink }}"
                  target="_blank"
                  name="linkedin"
                  rel="noopener"
                  ><img
                    src="/assets/ui/whatsapp.svg"
                    alt="linkedin"
                    width="32"
                    height="33"
                /></a>
              </div>
            }

            <div *ngIf="contactNumber" class="mt-0">
              <a [href]="'tel:' + contactNumber" class="text-lg">{{
                contactNumber
              }}</a>
            </div>
          </div>
          <div class="mt-4">
            <img src="/assets/ui/elta.svg" alt="ELTA" width="102" height="45" />
          </div>
          <div class="mt-4">
            <a [routerLink]="['/terms']" routerLinkActive="router-link-active">
              {{ 'ui.term-and-conditions' | transloco }}
            </a>
          </div>
          <div class="mt-4">
            <a [routerLink]="['/clients']" routerLinkActive="router-link-active">
              {{ 'ui.our-clients' | transloco }}
            </a>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col">
          <div class="text-lg uppercase font-light">
            {{ 'ui.our-projects' | transloco }}
          </div>
          <ul class="pm-ui-footer__lists">
            <li>
              <a
                href="https://profitmark.pro"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Profitmark.pro</a
              >
            </li>
            <li>
              <a
                href="https://attorneus.com/"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Attorneus.com</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.pro/franchising"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Franchising</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.pro/search-constructor"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark search constructor</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.pro/white-label"
                target="_blank"
                rel="noopener"
                class="text-white"
                >White label software</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.pro/crm"
                target="_blank"
                rel="noopener"
                class="text-white"
                >CRM system</a
              >
            </li>
          </ul>
        </div>
        <div class="col-12 md:col-6 lg:col">
          <div
            class="text-lg uppercase font-light text-overflow-clip"
            style="text-wrap: nowrap"
          >
            {{ 'ui.popular-trademark' | transloco }}
          </div>
          <ul class="pm-ui-footer__lists">
            <li>
              <a
                href="https://profitmark.us"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in USA</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.uk"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in UK</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.eu"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in EU</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.fr"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in France</a
              >
            </li>
            <li>
              <a
                href="https://profitmarke.de"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in Germany</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.it"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in Italy</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.ch"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in Switzerland</a
              >
            </li>
            <li>
              <a
                href="https://profitmark.nz"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in New Zealand</a
              >
            </li>
            <li>
              <a
                href="http://profitmark.ro"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in Romania</a
              >
            </li>
            <li>
              <a
                href="http://profitmark.cy"
                target="_blank"
                rel="noopener"
                class="text-white"
                >Trademark registration in Cyprus</a
              >
            </li>
          </ul>
        </div>
        <div class="col-12 md:col-6 lg:col">
          <div class="text-lg uppercase font-light">
            {{ 'ui.our-services' | transloco }}
          </div>
          <ul class="pm-ui-footer__lists">
            <li *ngFor="let domain of websites">
              <a [href]="'https://' + domain" target="_blank" rel="noopener">{{
                domain
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="pm-ui-footer__bottom_bar grid mt-8">
        <div class="md:col">
          © {{ companyName }} {{ currentYear }}<br />
          {{ companyAddress }}
        </div>
        <div class="md:col mt-4 md:mt-0">
          <img
            src="/assets/ui/pay-gates.svg"
            alt="Payment types"
            width="264"
            height="42"
          />
        </div>
      </div>
    </div>
  </div>
}
