import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { HeaderComponent } from './components/header/header.component';
import { PmHeaderMegamenuComponent } from './components/pm-header-megamenu/pm-header-megamenu.component';

@NgModule({
  imports: [
    CommonModule,
    MenubarModule,
    ButtonModule,
    DropdownModule,
    TranslocoModule,
    PmHeaderMegamenuComponent
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class PmUiPmHeaderModule {}
