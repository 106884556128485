import { filter, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as GeoLocationActions from './geo-location.actions';
import * as GeoLocationSelectors from './geo-location.selectors';

@Injectable()
export class GeoLocationFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(GeoLocationSelectors.selectGeoLocationLoaded)
  );
  allGeoLocation$ = this.store.pipe(
    select(GeoLocationSelectors.selectAllGeoLocation)
  );
  selectedGeoLocation$ = this.store.pipe(
    select(GeoLocationSelectors.selectEntity)
  );
  geoCountryCode$ = this.store.pipe(
    select(GeoLocationSelectors.selectAllGeoLocation),
    filter((geo) => !!geo),
    map((geo) => geo?.[0]?.countryCode)
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(GeoLocationActions.initGeoLocation());
  }
}
